import Vue from 'vue'
import VueRouter from 'vue-router'

Vue.use(VueRouter)

const routes = [
  {
    path: '/',
    name: 'Home',
    component: () => import(/* webpackChunkName: "home" */ '../views/Home.vue')
  },
  {
    path: '/:lang/hospitium',
    component: () => import(/* webpackChunkName: "hospitium" */ '../layout/main.vue'),
    children: [
      {
        path: '',
        name: 'Hospitium',
        component: () => import(/* webpackChunkName: "hospitium" */ '../views/Hospitium.vue')
      },
      {
        path: 'crc',
        name: 'crc',
        component: () => import(/* webpackChunkName: "crc" */ '../views/Crc.vue')
      },
      {
        path: 'contact',
        name: 'Contacto',
        component: () => import(/* webpackChunkName: "contacto" */ '../views/Contacto.vue')
      },
    ]
  },
  {
    path: '*',
    name: 'Not Found',
    component: () => import(/* webpackChunkName: "notfound" */ '../views/Error.vue')
  },

]

const router = new VueRouter({
  mode: 'history',
  //base: process.env.NODE_ENV === 'production' ? '/hospitium/' : '/',
  routes
})

export default router
